import { AppContext } from '@utils/AppContext';
import React, { useContext, useEffect, useState } from "react";
import { CatalogFilterGroup } from "./CatalogFilterGroup";
import { CatalogCheckboxFilter } from "./Filters/CatalogCheckboxFilter";
import { CatalogRadioFilter } from "./Filters/CatalogRadioFilter";
import { DatePicker, CommandBarButton, Stack } from "@fluentui/react";
import { ProductFilter } from "./Page/Catalog";
import { ProductFilterConst } from "@models/ProductFilter";
import { loginState } from "@components/Header/Header";
import { UserAPI } from "@utils/APIs/UserAPI";

interface ICatalogFilterProps {
  uiFilterCategories: string[];
  productFilter: ProductFilter;
  setFilter: (filterType: ProductFilterConst.FilterCollection, filter: string) => void;
  setProductFilter: (filter: ProductFilter) => void;
  clearFilterBtnVisible: boolean;
  clearFilters: () => void;
}

export const CatalogFilterArea: React.FC<ICatalogFilterProps> = (props) => {

  const context = useContext(AppContext);
  var categoryChild: JSX.Element[] = [];

  props.uiFilterCategories.forEach((cat) =>
  {
    var checked = props.productFilter.catFilters.includes(cat);
    categoryChild.push(<div key={cat}><CatalogCheckboxFilter label={cat} defaultChecked={(checked)} onFilterApplied={() => {props.setFilter(ProductFilterConst.FilterCollection.Category,cat) }} onFilterRemoved={() => { props.setFilter(ProductFilterConst.FilterCollection.Category,cat)}} filterApplied={checked} /></div>);
  }
  );

  let [usrLoginState, setLoggedIn] = useState(loginState.Uncertain);

  function isUserLoggedIn() {
    return usrLoginState === loginState.LoggedIn;
  }

  useEffect(() => {
    checkLoginState();
  }, []);

  async function checkLoginState() {
    const authed = await UserAPI.getIsLoggedIn();
    if (authed) {
      //we are authed so we know we will have a user
      setLoggedIn(loginState.LoggedIn);
    } else {
      setLoggedIn(loginState.LoggedOut);
    }
  }

  function render() {
    return (
      <div className="mt-4">
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <h4>{context.languagePack.catalog_page.filter_area_heading}</h4>
          {props.clearFilterBtnVisible && (
            <CommandBarButton
              className="clearFiltersButton sortButton"
              iconProps={{ iconName: "Cancel" }}
              title={"Clear Filters"}
              text={" Clear Filters"}
              onClick={props.clearFilters}
            />
          )}
        </Stack>
        <hr />
        <CatalogFilterGroup
          title="Search"
          active={props.productFilter.searchQuery ? true : false}
        >
          <CatalogRadioFilter
            name="Match"
            setSelected={(value) =>
              props.setProductFilter({
                ...props.productFilter,
                searchType: value,
              })
            }
            defaultSelected={
              props.productFilter.searchType ??
              ProductFilterConst.SearchTypePartial
            }
            /*disabled={!props.dateSorting}*/ options={[
              { label: "Partial", value: ProductFilterConst.SearchTypePartial },
              { label: "Exact", value: ProductFilterConst.SearchTypeExact },
            ]}
          />
        </CatalogFilterGroup>
        <CatalogFilterGroup
          title="Last Published Date"
          checkBox={true}
          active={true}
          enabled={props.productFilter.dateRangeFilter}
          setEnabled={(enabled) =>
            props.setProductFilter({
              ...props.productFilter,
              dateRangeFilter: enabled,
            })
          }
        >
          <DatePicker
            label="After"
            disabled={!props.productFilter.dateRangeFilter}
            placeholder="Select a date..."
            allowTextInput
            onSelectDate={(date) => {
              props.setProductFilter({
                ...props.productFilter,
                afterDate: date,
              });
            }}
            value={props.productFilter.afterDate ?? undefined}
            maxDate={new Date()}
          />
          <DatePicker
            label="Before"
            disabled={!props.productFilter.dateRangeFilter}
            placeholder="Select a date..."
            allowTextInput
            onSelectDate={(date) => {
              props.setProductFilter({
                ...props.productFilter,
                beforeDate: date,
              });
            }}
            value={props.productFilter.beforeDate ?? undefined}
          />
        </CatalogFilterGroup>
        {/* OCI Various Artifact Filtering  */}
        {/* For now we are only going to show the SBOM Filter if the user is logged in. In the future we may remove this if for public sboms */}
        {isUserLoggedIn() && (
          <CatalogFilterGroup title="Artifact Type" active={true}>
            <CatalogCheckboxFilter
              label="SBOM"
              onFilterApplied={() => {
                props.setFilter(
                  ProductFilterConst.FilterCollection.Artifact,
                  "SBOM"
                );
              }}
              onFilterRemoved={() => {
                props.setFilter(
                  ProductFilterConst.FilterCollection.Artifact,
                  "SBOM"
                );
              }}
              filterApplied={props.productFilter.artifactFilter.includes(
                "SBOM"
              )}
            />
          </CatalogFilterGroup>
        )}
        <CatalogFilterGroup
          title="Category"
          active={categoryChild.length > 0}
          inputChildren={categoryChild}
        ></CatalogFilterGroup>
        {/*Hiding OS and Architecture filters until we can actually determine this dynamically from the data*/}
        {/*<CatalogFilterGroup title="OS" active={true}>
          <CatalogCheckboxFilter label="Windows" onFilterApplied={() => {props.setFilter(ProductFilterConst.FilterCollection.OS,"Windows") }} onFilterRemoved={() => {props.setFilter(ProductFilterConst.FilterCollection.OS,"Windows")}} filterApplied={props.productFilter.osFilter.includes("Windows")} />
          <CatalogCheckboxFilter label="Linux" onFilterApplied={() => { props.setFilter(ProductFilterConst.FilterCollection.OS,"Linux") }} onFilterRemoved={() => {props.setFilter(ProductFilterConst.FilterCollection.OS,"Linux")}} filterApplied={props.productFilter.osFilter.includes("Linux")} />
        </CatalogFilterGroup>
        <CatalogFilterGroup title="Architectures" active={true}>
          <CatalogCheckboxFilter label="ARM" onFilterApplied={() => { props.setFilter(ProductFilterConst.FilterCollection.Architecture,"ARM")}} onFilterRemoved={() => {props.setFilter(ProductFilterConst.FilterCollection.Architecture,"ARM")}} filterApplied={props.productFilter.archFilter.includes("ARM")}/>
          <CatalogCheckboxFilter label="ARM 64" onFilterApplied={() => { props.setFilter(ProductFilterConst.FilterCollection.Architecture,"ARM 64")}} onFilterRemoved={() => { props.setFilter(ProductFilterConst.FilterCollection.Architecture,"ARM 64")}} filterApplied={props.productFilter.archFilter.includes("ARM 64")} />
          <CatalogCheckboxFilter label="x86" onFilterApplied={() => {props.setFilter(ProductFilterConst.FilterCollection.Architecture,"x86") }} onFilterRemoved={() => { props.setFilter(ProductFilterConst.FilterCollection.Architecture,"x86")}} filterApplied={props.productFilter.archFilter.includes("x86")} />
          <CatalogCheckboxFilter label="x86-64" onFilterApplied={() => { props.setFilter(ProductFilterConst.FilterCollection.Architecture,"x86-64")}} onFilterRemoved={() => { props.setFilter(ProductFilterConst.FilterCollection.Architecture,"x86-64")}} filterApplied={props.productFilter.archFilter.includes("x86-64")} />
        </CatalogFilterGroup>*/}
      </div>
    );
  }

  return render();
}